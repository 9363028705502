@use '../../shared/theme.scss' as theme;

div.jsa-check-box {
    padding-top: 6px;
    padding-bottom: 6px;
    margin-bottom: 0px;

    label.form-check-label {
        margin-left: 8px;
        font-size: 15px;
        font-weight: 300;
    }

    input.form-check-input{
        height: 20px;
        width: 20px;
        border-radius: 6px;
        border-width: 1px;
        margin-top: .15rem;
        border-color: theme.$grey;
    }
}

textarea.other-text-box {
    margin-left: 28px;
    padding: 8px;
    color: theme.$black;
    font-size: medium;
    resize: none;
    border: 1px solid theme.$border-grey;
    border-radius: 10px;
    border-color: theme.$border-grey;
    width: 90%;
    height: 100px;
}

div.flo-dropdown.medium.hazard-dropdown{
    width: 290px;
    color: theme.$charcoal;

    div.flo-dropdown--trigger {
       span {
        margin-right: 30px;
       }
       svg {
        font-size: 20px;
        margin-right: 0px;
        margin-top: 3px;
       }
    }
}

textarea.hazard-text-box {
    font-size: medium;
    height: 180px;
    width: 290px;
    resize: none;
    margin-top: 12px;
}