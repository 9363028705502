@import '../../shared/theme.scss';

.parts-button {
  background-color: white;
  padding: 14px 15px 15px 15px;
  max-height: 50px;
  width: 50%;
}

.parts-button:hover {
  box-shadow: 0 0 5px;
}

.parts-button-active {
  background-color: white;
  padding: 14px 15px 15px 15px;
  width: 50%;
}

.parts-card {
  border-color: transparent !important;
}

.parts-dropdown-div {
  border-top: solid;
  border-color: $border-grey;
  border-width: 1px;
  margin-top: 15px;
  padding: 15px 0px 0px 5px;
}

.parts-header {
  padding-bottom: 10px;
  font-size: 14px;
}

.add-parts-button {
  margin: 10px 0px 0px 10px;
}

.search-div {
  display: flex;
  justify-content: space-between;
}

.parts-div {
  display: grid;
  padding-top: 20px;
  font-size: 14px;
}

.parts-label {
  padding: 5px 5px 5px 0px;
}

.label-icon {
  font-size: 30px;
  margin-bottom: 2px;
}

.parts-close {
  padding-left: 20px;
  font-size: 10px;
  font-color: $black
}

div.small {
   width: 70%;
}

div.flo-search-bar--small {
  width: unset;
  min-width: 200px !important;
}