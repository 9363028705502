@import '../../../shared/theme.scss';

.ge-checklist-button {
  background-color: white;
  padding: 14px 15px 15px 15px;
  margin-right: 10px;
  max-height: 50px;
  min-width: 780px !important;
  width: 100%;
}

.ge-checklist-button:hover {
  box-shadow: 0 0 5px;
}

.ge-checklist-button-active {
  background-color: white;
  padding: 14px 15px 15px 15px;
  margin-right: 10px;
  min-width: 780px !important;
  width: 100%;
}

.ge-card {
  border-color: transparent !important;
}

.ge-dropdown-div {
  display: flex;  
  border-top: solid;
  border-color: $border-grey;
  border-width: 1px;
  margin-top: 15px;
  padding-top: 15px;
  font-size: 11px !important;
}

.ge-checkbox-div {
  width: 30%;
}

.ge-input-div {
  display: flex;
  padding: 10px 10px 10px 10px;
  width: 70%;
  justify-content: space-between;
}

div.ge-checkbox {
  padding: 0px 0px 10px 0px;

  label.form-check-label {
    padding: 10px 0px 10px 10px;
  }
  input.form-check-input {
    height: 15px;
    width: 15px;
    border-radius: 5px;
    margin: 10px 0px 10px 10px;
  }
}

.ge-input {
  margin: 5px 0px 10px 0px;
  height: 35px;
  padding: 0px 10px 0px 10px !important;
  font-size: 12px !important;
}

.ge-label {
  font-size: 11px !important;
}

.ge-header {
  font-size: 11px !important;
  padding-bottom: 10px;
}

.ge-button {
  background-color: transparent !important;
  border-color: transparent !important;
  margin-left: -15px;
  color: $select-blue;
  font-size: 12px;
}

.ge-button:hover {
  color: $soft-blue;
}

.rec-div {
  padding: 10px 10px 10px 10px;
}

.cylinder-div {
  display: flex;
  flex-wrap: wrap;
}

.div {
  padding: 10px 75px 10px 0px;
}

.close-button {
  margin-left: 40px;
}