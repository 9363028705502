@import '../../shared/theme.scss';

.div {
  padding: 0;
}

.bill-info-dropdown-div {
  border-top: solid;
  border-color: $border-grey;
  border-width: 1px;
  margin-top: 15px;
}

.bill-info-button {
  background-color: white;
  padding: 9px 15px 10px 15px;
  margin-right: 10px;
  max-height: 50px;
  width: 33%;
  min-width: 175px !important;
}

.bill-info-button-active {
  background-color: white;
  padding: 9px 15px 15px 15px;
  margin-right: 10px;
  width: 33%;
}

.bill-info-button:hover {
  box-shadow: 0 0 5px;
}

.bill-info-div {
  border-color: transparent !important;
  margin: 5px 0px 0px 0px;
}

.bill-info-card {
  display: flex;
  margin: 15px 0px 0px 0px;
  padding: 10px 10px 10px 0px;
  border-width: 1px;
  border-style: solid;
  border-color: $border-grey;
  border-radius: 10px;
}

.bill-info-card:hover {
  box-shadow: 0 0 5px;
}

div.bill-info-checkbox {
  padding: 0px 0px 0px 15px;

  label.form-check-label {
    padding-top: 7px;
    margin-left: 10px;
    font-size: 14px;
  }
  input.form-check-input {
    height: 15px;
    width: 15px;
    margin: 10px 0px 8px 0px;
    border-radius: 5px;
  }
}

