@import '../../shared/theme.scss';

div {
  padding-bottom: 0;
}

.emission-button {
  background-color: white;
  padding: 14px 15px 15px 15px;
  max-height: 50px;
  min-width: 380px !important;
  width: 50%;
}

.emission-button:hover {
  box-shadow: 0 0 5px;
}

.emission-button-active {
  background-color: white;
  padding: 14px 15px 15px 15px;
  min-width: 380px !important;
  max-height: 590px;
  width: 50%;
}

.emissions-card {
  border-color: transparent !important;
}

.emissions-dropdown-div {
  display: flex;  
  border-top: solid;
  border-color: $border-grey;
  border-width: 1px;
  margin-top: 15px;
  padding-top: 15px;
}

.emissions-input {
  height: 10%;
  margin: 0px 0px 0px 0px;
  padding-left: 10px !important;
}

.emissions-label {
  padding: 10px 0px 10px 0px;
  font-size: 12px;
}

.emissions-dropdown {
  padding: 0px 0px 5px 0px;
  font-size: 12px;
}

div.emissions-checkbox {
  padding: 0px 10px 10px 0px;

  label.form-check-label {
    padding-top: 0px;
    font-size: 12px;
  }
  input.form-check-input {
    height: 15px;
    width: 15px;
    border-radius: 5px;
    margin: 5px 10px 10px 0px;
  }
}

.emissions-div {
  display: flex;
  padding: 0;
  margin: 0;
}