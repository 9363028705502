@use '../../shared/theme.scss' as theme;

div.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    padding: 0px;
    margin-right: 0;
    margin-left: 0;
}

div.jsa-modal {
    .modal-dialog{
        width: 1000px;
        max-width: 100%;
    }
    .modal-header{
        border-width: 0px;
    }
    div.modal-body{
        padding-top: 0px;
        margin: 8px;
    }
}

ul.jsa-nav{
    background-color: theme.$tab-grey;
    border-radius: 8px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 4px;
    .nav-link{
        color: theme.$charcoal;
        &:hover {
            color: theme.$black;
        }
    }
    .nav-item .active{
        color: theme.$black;
    }
    .nav-item .active {
        background-color: theme.$white;
    }
}

div.flo-dropdown.medium{
    width: 256px;
    margin-right: 20px;
    color: theme.$charcoal;
    .flo-dropdown--icon{
        margin-left: 0px;
    }
    .flo-dropdown--trigger{
        padding: 8px ;
    }
    &::placeholder {
        color: theme.$charcoal
    }
}

div.flo-dual-input {
    margin-right: 20px;
    &::placeholder{
        color: theme.$charcoal;
    }
    &--single-container {
        input.flo-dual-input--left{
            padding-top: 8px ;
            padding-bottom: 8px ;
        }
        input.flo-dual-input--right{
            padding-top: 8px ;
            padding-bottom: 8px ;
        }
        span.flo-dual-input--suffix{
            top: 8px;
        }
    }
}

.flo-button {
    height: 35px;
    display: inline-block;
    text-align: center;
    line-height: 0px;
}

.label {
    padding-right: 16px;
    padding-left: 2px;
    text-align: left;
}

.title {
    font-weight: 600;
    margin-bottom: 6px;
    font-size: 16px;
}

.sub-title {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 14px;
}

.sub-section-header {
    padding-bottom: 8px;
}

div.flex-break {
    flex-basis: 100%;
    height: 0;
}

div.jsa-check-box {
    padding-top: 6px;
    padding-bottom: 6px;
    margin-bottom: 0px;

    label.form-check-label {
        margin-left: 8px;
        font-size: 15px;
        font-weight: 300;
    }

    input.form-check-input{
        height: 20px;
        width: 20px;
        border-radius: 6px;
        border-width: 1px;
        margin-top: .15rem;
        border-color: theme.$grey;
    }
}

.jsa-check-box-container {
    width: 310px;
    padding-right: 30px;
}

textarea {
    padding: 12px;
    font-size: medium;
    border-radius: 10px;
    color: theme.$black;
    border: 1px solid theme.$border-grey;
    &:focus {
        border-width: 1px;
        border-color: theme.$select-blue;
        outline: none;
    }
}