@import '../../shared/theme.scss';

.contact-block {
    position: relative;
    margin-right: 10px;
    margin-bottom: 24px;
}

.label {
    font-weight: 400;
    padding-bottom: 2px;
    font-size: 15px;
}

.contact-dropdown {
    padding-bottom: 6px;
    color: $charcoal;
}

.flo-input.contact-phone-number-input {
    width: 256px;
    padding: 8px;
    &::placeholder {
        width: 256px;
        padding: 8px;
        color: $charcoal;
    }
}

.flo-input.contact-name-input {
    width: 256px;
    padding: 8px;
    margin-right: 20px;
    margin-bottom: 6px;
}

button.delete-contact {
    height: .4rem;
    width: .4rem;
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 24px;
}

button.button-add-contact {
    background-color: $transparent;
    box-shadow: 2px 2px 10px $transparent;
    color: $soft-blue;
    padding: 0;
    border-radius: 0;
    border: 0px;
    min-width: 0px;
    margin-left: 6px;
}
