@import '../../shared/theme.scss';

.ant-menu {
  background-color: $off-white;
  height: 100vh;
  width: 100px !important;
  margin: 0px !important;
  margin-left: -6px !important;
  padding: 0 !important;
}

.ant-menu-item-selected{
  background-color: $black !important;
  color: $white !important;
}

.ant-menu-item {
  color: $charcoal;
  height: 60px !important;
  width: 100px !important;
  padding: 0 !important;
  padding-left: 30px !important;
  padding-top: 10px !important; 
  margin: 0 !important;
}

.ant-menu-item-icon {
  width: 80%;
  height: 80%;
}

.ant-menu-item:hover {
  background-color: $almost-white;
}
