.header-page {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 20px 20px;
  background-color: #f0f0f0;
}

.header-text {
  font-family: "Inter" sans-serif;
  font-weight: 600;
}

.header-image {
  height: 30px;
}