@import '../../shared/theme.scss';

.mileage-button {
  background-color: white;
  margin-right: 10px;
  width: 33%;
  padding: 10px 15px 10px 15px;
  max-height: 50px;
}

.mileage-button-active {
  background-color: white;
  border-radius: 7.5px;
  border-style: solid;
  border-width: 1px;
  margin-right: 10px;
  height: 150px;
  max-height: 150px;
  width: 33%;
  padding: 10px 15px 10px 15px;
}

.mileage-card {
  border: transparent !important;
  margin: 5px 0px 0px 0px;
}

.mileage-div {
  display: flex;
  margin: 10px 0px 10px 0px;
}

.mileage-dropdown-div {
  border-top: solid;
  border-color: $border-grey;
  border-width: 1px;
  margin-top: 15px;
  padding-top: 5px;
}

.mileage-input {
  font-size: 14px !important;
  height: 40px;
  padding-left: 10px !important;
  margin: 0px 0px 0px 0px;
  width: auto;
}

.padding-label {
  padding: 5px;
}

.mileage-button:hover {
  box-shadow: 0 0 5px;
}