$black: #000000;
$white: #ffffff;
$transparent: #ffffff00;

$almost-white: #FDFDFD;
$off-white: #F0F0F0;
$charcoal: #5a5a5a;
$grey: #667085;
$soft-blue: #6495ED;
$select-blue: #3285ff;
$border-grey: #cccccc;
$tab-grey: #EAECF0;