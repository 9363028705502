@import '../../shared/theme.scss';

.pm-button {
  background-color: white;
  padding: 14px 15px 15px 15px;
  margin-right: 10px;
  max-height: 50px;
  min-width: 590px !important;
  width: 100%;
}

.pm-button:hover {
  box-shadow: 0 0 5px;
}

.pm-button-active {
  background-color: white;
  padding: 14px 15px 15px 15px;
  margin-right: 10px;
  width: 100%;
  max-height: 590px !important;
  min-width: 590px !important;
}

.pm-card {
  border-color: transparent !important;
}

.pm-dropdown-div {
  border-top: solid;
  border-color: $border-grey;
  border-width: 1px;
  margin-top: 15px;
  padding: 15px 0px 10px 5px;
}

.pm-div {
  padding: 10px 10px 10px 10px;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.pm-space-div {
  min-width: 275px;
  width: 30%;
}

div.checkbox {
  padding: 0px 10px 10px 20px;

  label.form-check-label {
    padding-top: 3px;
    margin-left: 10px;
  }
  input.form-check-input {
    height: 15px;
    width: 15px;
    border-radius: 5px;
  }
}

.pm-label {
  padding: 5px 10px 10px 0px;
}

.pm-input {
  height: 30px;
  padding-left: 10px !important;
  margin: 0px 0px 10px -5px;
  font-size: 12px !important;
}

.pm-bottom-div {
  margin-left: 10px;
}