@use '../../shared/theme.scss' as theme;

textarea.text-box {
    font-size: medium;
    max-height: 400px;
    max-width: 950px;
    min-height: 180px;
    min-width: 506px;
    resize: auto;
}

textarea.text-box::placeholder {
    color: theme.$charcoal;
}