@import '../../shared/theme.scss';

div.weather-conditions-dropdown {
    width: 320px;
    margin-bottom: 18px;
}

input.wind-speed {
    padding: 8px;
    width: 236px;
    margin-bottom: 18px;
    &--suffix {
        right: 28px;
    }
}

span.flo-input--suffix {
    right: 28px;
}

textarea.muster-text-box {
    padding: 8px;
    margin-right: 22px;
    font-size: medium;
    resize: none;
}