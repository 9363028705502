.home {
  padding: 10px 10px 10px 10px;
}

.home-card {
  border-style: solid;
  border-width: 2px;
  border-color: hsl(0, 0%, 94%);
  border-radius: 5px;
  padding: 10px 10px 10px 10px;
  width: auto;
}

.home-div {
  display: flex;
  text-align: left;
}

.home-header {
  font-family: "Inter" sans-serif;
  font-weight: 600;
  padding-bottom: 50px;
}

.home-label {
  font-family: "Inter" sans-serif;
  font-weight: 500;
  padding-left: 20px;
}

.home-button {
  height: 50px !important;
}
