@import '~bootstrap/scss/bootstrap';
@import './shared/theme.scss';

* {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}

body {
  min-width: 700px;
}

h6 {
  margin: 0;
}

.container-page {
  padding: 0;
  margin: 0;
  display: flex;
  margin: 0;
  padding: 0;
}

.sidenav {
  width: 95px !important;
}

.main {
  flex-grow: 1;
  background-color: $white;
} 

.loading-in {
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  min-height: 100%;
  padding-top: 20%;
}
