.new-work {
  padding: 10px 10px 10px 10px;
}

.new-work-card {
  border-style: solid;
  border-width: 2px;
  border-color: #F0F0F0;
  border-radius: 5px;
  padding: 10px 10px 10px 10px;
  width: auto;
  height: 100%;
}

.new-work-div {
    display: flex;
    justify-content: space-between;
}

.new-work-card-div {
  display: flex;
  padding: 10px 0px 10px 0px;
}

.full-button {
  background-color: white;
  padding: 15px 15px 15px 15px;
  max-height: 50px;
  width: 100%;
}

.full-button:hover {
  box-shadow: 0 0 5px;
}